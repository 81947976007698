<template>
  <div>
    系统设置
  </div>
</template>
<script>
export default {
    name: 'Settings'
}
</script>

<style>

</style>